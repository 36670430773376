import { Link } from "react-router-dom";
import icon from "../../assets/icon.png";
import { useState } from "react";
import Error from "../../components/Error";
import { login } from "../../functions/auth";
import { UserAuth } from "../../context/AuthContext";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await login(username, password);
    if (response.error) {
      setError(true);
      setErrorMessage(response.error);
      setIsLoading(false);
    } else {
      setError(false);
      setErrorMessage("");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-com"></div>
        </div>
      )}
      <div className="min-h-screen h-full bg-gradient-to-r from-com/20 via-white to-com/20">
        <div className="h-full">
          <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8  ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-10 w-auto"
                src={icon}
                alt="Your Company"
              />
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Please sign in to your studio account.
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                {error && <Error message={errorMessage} /> }
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-com focus:ring-com"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a
                        href="#"
                        className="font-semibold text-com hover:text-comhover"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-sm bg-com px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-comhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com"
                      onClick={handleLogin}
                    >
                      Sign in
                    </button>
                  </div>
                </form>

                <div>
                  <div className="relative mt-10">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">
                        Don't have an account?
                      </span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <Link
                      to="/create-account"
                      type="button"
                      className="inline-flex items-center justify-center w-full gap-x-2 rounded-sm ring-1 ring-inset ring-com bg-white px-3.5 py-2.5 text-sm font-semibold text-com shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com uppercase mr-2"
                    >
                      Create an account
                    </Link>
                  </div>
                </div>
              </div>

              <p className="mt-10 text-center text-sm text-gray-500">
                Want more info?{" "}
                <a
                  href="#"
                  className="font-semibold leading-6 text-com hover:text-comhover"
                >
                  Schedule a demo
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
