import logo from "../assets/logo.png";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  BarChart,
  ChevronRightIcon,
  Coins,
  DollarSign,
  FileStack,
  GraduationCap,
  Home,
  Unlock,
  Users,
  Users2,
} from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { logout } from "../functions/auth";
import Help from "./components/Help";
import { searchByValue, searchProspectsByValue } from "../functions/api";

const navigationItems = [
  {
    name: "Dashboard",
    href: "/dashboard",
    current: true,
    icon: <Home className="h-5 w-5 text-white" />,
  },
  {
    name: "Students",
    current: false,
    icon: <Users className="h-5 w-5 text-white " />,
    children: [
      { name: "View Students", href: "/students" },
      { name: "Add Student", href: "/students/add-student" },
      { name: "Search Students", href: "/students/search-students" },
      { name: "Student Pipeline", href: "/students/student-pipeline" },
    ],
  },
  {
    name: "Payments",
    current: false,
    icon: <Coins className="h-5 w-5 text-white" />,
    children: [
      { name: "Search Payments", href: "/payments" },
      {
        name: "Late Payment Pipeline",
        href: "/payments/late-payment-pipeline",
      },
      { name: "View Invoices", href: "/payments/view-invoices" },
    ],
  },
  {
    name: "Staff",
    current: false,
    icon: <Users2 className="h-5 w-5 text-white" />,
    children: [
      { name: "View Staff", href: "/staff" },
      { name: "Add Staff", href: "/staff/add-staff" },
      { name: "Search Staff", href: "/staff/search-staff" },
    ],
  },
  {
    name: "Classes",
    current: false,
    icon: <GraduationCap className="h-5 w-5 text-white" />,
    children: [
      { name: "View Classes", href: "/classes" },
      { name: "Attendance", href: "/classes/attendance" },
      { name: "Rooms", href: "/classes/rooms" },
      { name: "Waiting Lists", href: "/classes/waiting-lists" },
      { name: "Programs", href: "/classes/programs" },
      { name: "Ranks", href: "/classes/ranks" },
    ],
  },
  {
    name: "Marketing",
    current: false,
    icon: <BarChart className="h-5 w-5 text-white" />,
    children: [
      { name: "Methods", href: "/marketing/methods" },
      { name: "Add Prospect", href: "/marketing/add-prospect" },
      { name: "Search Prospects", href: "/marketing/search-prospects" },
      { name: "Propsect Pipeline", href: "/marketing/prospect-pipeline" },
      { name: "Daily Schedule", href: "/marketing/daily-schedule" },
    ],
  },
  // {
  //   name: "Reports",
  //   current: false,
  //   icon: <FileStack className="h-5 w-5 text-white" />,
  //   children: [
  //     { name: "Student Reports", href: "/reports/student-reports" },
  //     { name: "Payment Reports", href: "/reports/payment-reports" },
  //     { name: "Marketing Reports", href: "/reports/marketing-reports" },
  //   ],
  // },
  {
    name: "Advanced",
    current: false,
    icon: <Unlock className="h-5 w-5 text-white" />,
    children: [
      { name: "View Emails", href: "/advanced/emails" },
      { name: "View NewsLetters", href: "/advanced/newsletters" },
      { name: "Emailing Lists", href: "/advanced/emailing-lists" },
      { name: "Text Messages", href: "/advanced/text-messages" },
      { name: "Capture Forms", href: "/advanced/capture-forms" },
      { name: "Performance Planner", href: "/advanced/performance-planner" },
      { name: "Waivers", href: "/advanced/waivers" },
      { name: "Student Portal", href: "/advanced/student-portal" },
    ],
  },
];
const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];
const userNavigation = [
  { name: "Your profile", href: "/profile" },
  { name: "Account", href: "/account-status" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard({ children }) {
  const {
    studioInfo,
    suid,
    setSearchedStudentsAndProspects,
  } = UserAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(navigationItems);
  const [searchItem, setSearchItem] = useState("");

  const searchParams = {
    studioId: suid,
    searchValue: searchItem,
  };

  const handleLogout = () => {
    logout();
  };

  const path = useLocation().pathname;
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    let students = [];
    let prospects = [];
    await searchByValue(searchParams).then((response) => {
      students = response.recordset;
    });
    await searchProspectsByValue(searchParams).then((response) => {
      prospects = response.recordset;
    });
    setSearchedStudentsAndProspects({ students, prospects });
    navigate("/search");
  };

  useEffect(() => {
    setNavigation((nav) =>
      nav.map((item) => {
        if (item.children) {
          const current = item.children.some((child) => child.href === path);
          return { ...item, current };
        }
        return { ...item, current: item.href === path };
      })
    );
  }, [path]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-950 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-6 w-auto"
                        src={logo}
                        alt="Compete Studio"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-950 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img className="h-5 w-auto" src={logo} alt="Compete Studio" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {!item.children ? (
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-zinc-800"
                                : " hover:text-gray-200 hover:font-bold",
                              "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-white"
                            )}
                          >
                            {item.icon}
                            {item.name}
                          </Link>
                        ) : (
                          <Disclosure as="div">
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "bg-zinc-800"
                                      : "hover:font-bold hover:text-gray-200",
                                    "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-white"
                                  )}
                                >
                                  {item.icon}
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? "rotate-90  text-white  hover:text-gray-200"
                                        : "text-white hover:text-gray-200",
                                      "h-5 w-5 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          subItem.href === path
                                            ? "font-extrabold text-xl bg-zinc-700"
                                            : " hover:font-bold text-sm",
                                          "block rounded-md py-2 pr-2 pl-9  leading-6 text-white"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {searchItem && (
                  <button
                    type="submit"
                    className="rounded-sm bg-com px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-comhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com flex items-center"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                )}
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div className="-m-1.5 flex items-center p-1.5">
                  <Help />
                </div>
                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      <span className="text-sm font-medium leading-none text-white">
                        {studioInfo?.Contact_Name[0]
                          ? studioInfo?.Contact_Name[0]
                          : "S"}
                      </span>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {studioInfo?.Contact_Name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={handleLogout}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10 bg-zinc-100 min-h-screen h-full">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
