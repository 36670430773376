import { Link, useParams } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import { getProspectsByPipelineStep } from "../../../functions/api";
import dateFormat, { masks } from "dateformat";


export default function ProspectPipelineRoster() {
  const { prospectPipelineSteps, suid } = UserAuth();
  const [people, setPeople] = useState([]);

  const { id } = useParams();

  const title = prospectPipelineSteps.find(
    (step) => step.PlacementOrdinal === parseInt(id)
  );

  useEffect(() => {
    getProspectsByPipelineStep(title?.PipelineStepId, suid).then((data) => {
      setPeople(data.recordset);
    });
  }, []);

  console.log(people);

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {title?.StepName}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{title?.Description}</p>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="w-full text-left">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 "
                    >
                      Last Name
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Entry Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Intro Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      First Class
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Next Contact
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      View Prospect
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Delete
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Activate as Student
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr key={person.ProspectId} className={`${
                      new Date(person?.NextContactDate) <= new Date() && "bg-cs"
                    }`}>
                      <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ">
                        {person.LName}
                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.FName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.EntryDate && dateFormat(person.EntryDate, "mm/dd/yyyy")}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.IntroDate && dateFormat(person.IntroDate, "mm/dd/yyyy")}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.FirstClassDate && dateFormat(person.FirstClassDate, "mm/dd/yyyy")}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.NextContactDate && dateFormat(person.NextContactDate, "mm/dd/yyyy")}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <Link 
                          to={`/marketing/prospect-pipeline/view-prospect/${person.ProspectId}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        > 
                          View
                        </Link>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <a href="#" className="text-red-600 hover:text-red-900">Delete</a>
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500">
                      <button className="text-indigo-600 hover:text-indigo-900">Activate</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
