import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../Students/Subs/dist/react-draft-wysiwyg.css";
import { UserAuth } from "../../../context/AuthContext";
import { addAProspectPipelineStep, addAStudentPipelineStep, getUploadedStudioImages } from "../../../functions/api";
import toast, { Toaster } from "react-hot-toast";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export default function AddStudentPipelineStep() {
  const { suid } = UserAuth();
  const [pipelineStepName, setPipelineStepName] = useState("");
  const [notes, setNotes] = useState("");
  const [smsDefaultText, setSmsDefaultText] = useState("");
  const [emailDefaultText, setEmailDefaultText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    getUploadedStudioImages(suid).then((res) => {
      setImages(res.recordset);
    });
  }, [suid]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // let rawContentState = convertToRaw(editorState?.getCurrentContent());
  // const markup = draftToHtml(rawContentState);
  // // const markup = "";

  // console.log(markup);
  // console.log(editorState?._immutable?.currentContent.getPlainText());

  const handlePipelineStepNameChange = (e) => {
    setPipelineStepName(e.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSmsDefaultTextChange = (e) => {
    setSmsDefaultText(e.target.value);
  };

  const handleEmailDefaultTextChange = (e) => {
    setEmailDefaultText(e.target.value);
  };

  const handleCopyImageURL = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("URL Successfully Copied!");
  };

  const data = {
    studioId: suid,
    stepName: pipelineStepName,
    description: notes,
    defaultEmailSubject: emailSubject,
    defaultEmailText: draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ),
    defaultSMSText: smsDefaultText,
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // Simulating submission by logging the form values after 1 second
    addAProspectPipelineStep(data).then((res) => {
      if (res.status === 200) {
        toast.success("Pipeline Step Added Successfully!");
      } else {
        toast.error("An Error Occurred. Please try again.");
      }
    });
    setTimeout(() => {
      console.log(data);
      setIsLoading(false);
      window.location.href = "/marketing/prospect-pipeline";
      
    }, 1000);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold leading-6 text-gray-900">
            Add a A New Prospect Pipeline Step
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Please fill out the form below to add a new pipeline step.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Pipeline Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Fill out the form below to add a new pipeline step.
            </p>
          </div>
          <div className="overflow-hidden rounded-lg bg-gray-50 shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-5 sm:p-6">
              <div className="mb-4">
                <label
                  htmlFor="pipelineStepName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pipeline Step Name
                </label>
                <input
                  type="text"
                  id="pipelineStepName"
                  name="pipelineStepName"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                  value={pipelineStepName}
                  onChange={handlePipelineStepNameChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="notes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Notes
                </label>
                <textarea
                  id="notes"
                  name="notes"
                  rows="3"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 "
                  value={notes}
                  onChange={handleNotesChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="smsDefaultText"
                  className="block text-sm font-medium text-gray-700"
                >
                  SMS Default Text
                </label>
                <textarea
                  id="smsDefaultText"
                  name="smsDefaultText"
                  rows="3"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 "
                  value={smsDefaultText}
                  onChange={handleSmsDefaultTextChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="pipelineStepName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Default Subject
                </label>
                <input
                  type="text"
                  id="emailSubject"
                  name="emailSubject"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
              <div className="mb-4 w-full ">
                <label
                  htmlFor="emailDefaultText"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Default Text
                </label>
                <div className="">
                  <Editor
                    editorState={editorState}
                    editorStyle={{
                      height: "400px",
                      border: "2px solid #f1f1f1",
                      padding: "0 10px",
                      backgroundColor: "white",
                    }}
                    onEditorStateChange={onEditorStateChange}
                  />              
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={handleSubmit}
                  className="bg-com text-white px-4 py-2 rounded-sm hover:bg-comhover focus:outline-none focus:bg-com ml-auto"
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
            {/* Images */}
            <div className="px-4 py-5 sm:p-6">
              <div className="mb-4">
                <label
                  htmlFor="pipelineStepName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Images
                </label>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Select an Image to Copy the URL and Add it to your Email
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {images.map((image) => (
                    <button
                      key={image.ImageId}
                      onClick={() =>
                        handleCopyImageURL(
                          `https://competestudio.com${image.ImageURL.slice(1)}`
                        )
                      }
                    >
                      <img
                        src={`https://competestudio.com${image.ImageURL.slice(
                          1
                        )}`}
                        alt={image.ImageName}
                        className="w-full h-32 object-cover rounded-md"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
