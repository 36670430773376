import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createUser } from "../../functions/auth";

export default function CreateAccount() {
  //   const {setIsloading, setIsLoggedIn} = UserAuth();
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [studio_Name, setStudio_Name] = useState("");
  const [contact_Number, setContact_Number] = useState("");
  const [contact_Email, setContact_Email] = useState("");
  const [contact_Address, setContact_Address] = useState("");
  const [contact_City, setContact_City] = useState("");
  const [contact_State, setContact_State] = useState("");
  const [contact_Zip, setContact_Zip] = useState("");
  const [method_of_Contact, setMethod_of_Contact] = useState("");
  const [is_Activated, setIs_Activated] = useState(1);
  const [desired_UserName, setDesired_UserName] = useState("");
  const [desired_Pswd, setDesired_Pswd] = useState("");
  const [salt, setSalt] = useState("firbase");
  const [paysimpleCustomerId, setPaysimpleCustomerId] = useState(0);
  const [role, setRole] = useState("Admin");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userData = {
    studio_Name,
    contact_Name: firstName + " " + lastName,
    contact_Number,
    contact_Email,
    contact_Address,
    contact_City,
    contact_State,
    contact_Zip,
    method_of_Contact,
    is_Activated,
    desired_UserName,
    desired_Pswd,
    salt,
    paysimpleCustomerId,
    role,
    Studio_Id: 156321,
    userRole: "Admin",
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    setLoading(true);
    if (desired_Pswd !== confirmPassword) {
      alert("Passwords do not match");
      setLoading(false);
      return;
    } else {
      createUser(contact_Email, confirmPassword, userData)
        .then((res) => {
          if (res.status === "success") {
            setLoading(false);
            window.location.href = "/";
          } else if (res.error) {
            setLoading(false);
            setError(true);
            setErrorMessage(res.error);
          } else {
            setLoading(false);
            console.log(res);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error creating user:", error); // Log the error
          // You can handle the error or return a response indicating the failure
        });
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-com"></div>
        </div>
      )}
      <div className="min-h-screen h-full bg-gradient-to-r from-com/20 via-white to-com/20 py-12">
        <div className="max-w-xl mx-auto px-8 pb-24 bg-white py-12 shadow sm:rounded-lg sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create a new studio account.
            </h2>
          </div>

          <p className="text-center text-sm text-gray-600">
            Or{" "}
            <Link
              to="/login"
              className="font-medium text-orn hover:text-ornhover"
            >
              sign in to your existing account
            </Link>
          </p>

          <div className="mt-6 grid sm:grid-cols-4 grid-cols-1 sm:gap-2">
            <div className="sm:col-span-4">
              <label
                htmlFor="studio-name"
                className="block text-xs font-medium text-gray-900"
              >
                Studio Name
              </label>
              <input
                type="text"
                name="studio-name"
                id="studio-name"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Studio Name"
                onChange={(e) => setStudio_Name(e.target.value)}
              />
            </div>
            <div className="sm:col-span-4 mt-2">
              <label
                htmlFor="user-name"
                className="block text-xs font-medium text-gray-900"
              >
                Username
              </label>
              <input
                type="text"
                name="user-name"
                id="user-name"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Username"
                onChange={(e) => setDesired_UserName(e.target.value)}
              />

              {error && (
                <p className="text-red-500 text-xs mt-1">{errorMessage}</p>
              )}
            </div>
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="first-name"
                className="block text-xs font-medium text-gray-900"
              >
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="last-name"
                className="block text-xs font-medium text-gray-900"
              >
                Last Name
              </label>
              <input
                type="text"
                name="last-name"
                id="last-name"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-4">
              <label
                htmlFor="phone"
                className="block text-xs font-medium text-gray-900"
              >
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Phone Number"
                onChange={(e) => setContact_Number(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-4">
              <label
                htmlFor="address"
                className="block text-xs font-medium text-gray-900"
              >
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Address"
                onChange={(e) => setContact_Address(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="city"
                className="block text-xs font-medium text-gray-900"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="City"
                onChange={(e) => setContact_City(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label
                htmlFor="state"
                className="block text-xs font-medium text-gray-900"
              >
                State
              </label>
              <select
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                onChange={(e) => setContact_State(e.target.value)}
              >
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="mt-2">
              <label
                htmlFor="zip"
                className="block text-xs font-medium text-gray-900"
              >
                Zip Code
              </label>
              <input
                type="text"
                name="zip"
                id="zip"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Zip Code"
                onChange={(e) => setContact_Zip(e.target.value)}
              />
            </div>

            <div className="mt-2 sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="you@email.com"
                onChange={(e) => setContact_Email(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="password"
                className="block text-xs font-medium text-gray-900"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Password"
                onChange={(e) => setDesired_Pswd(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="confirm"
                className="block text-xs font-medium text-gray-900"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm"
                id="confirm"
                className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-com sm:text-sm sm:leading-6 h-12"
                placeholder="Re-enter password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="mt-2 sm:col-span-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-com px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-comhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com"
                onClick={(e) => handleCreateUser(e)}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
