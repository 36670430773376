import React, { Suspense } from "react";
import Dashboard from "../studios/Dashboard";
import { Route, Routes } from "react-router-dom";
import Chat from "../studios/components/Chat";
import SearchDisplay from "../studios/pages/SearchDisplay";
import AddStudentToClass from "../studios/Students/Subs/AddStudentToClass";
import AddProspectPipelineStep from "../studios/Marketing/Subs/AddProspectPipelineStep";
import ProspectPipelineRoster from "../studios/Marketing/Subs/ProspectPipelineRoster";
const Overview = React.lazy(() => import("../studios/Overview"));
const Students = React.lazy(() => import("../studios/Students/ViewStudents"));
const AddStudent = React.lazy(() => import("../studios/Students/AddStudent"));
const Support = React.lazy(() => import("../studios/pages/Support"));
const SoftwareTraining = React.lazy(() =>
  import("../studios/pages/SoftwareTraining")
);
const AccountStatus = React.lazy(() =>
  import("../studios/pages/AccountStatus")
);
const Profile = React.lazy(() => import("../studios/pages/Profile"));
const ManageAccountInfo = React.lazy(() =>
  import("../studios/pages/ManageAccountInfo")
);
const ViewStudentEmailHistory = React.lazy(() =>
  import("../studios/Students/Subs/ViewStudentEmailHistory")
);
const SearchStudents = React.lazy(() =>
  import("../studios/Students/SearchStudents")
);
const ViewStudentsInPipeline = React.lazy(() =>
  import("../studios/Students/Subs/ViewStudentsInPipeline")
);
const EditPipelineStep = React.lazy(() =>
  import("../studios/Students/Subs/EditPipelineStep")
);
const ViewStudent = React.lazy(() => import("../studios/Students/ViewStudent"));
const AddStudentPipelineStep = React.lazy(() =>
  import("../studios/Students/Subs/AddStudentPipelineStep")
);
const EditStudent = React.lazy(() =>
  import("../studios/Students/Subs/EditStudent")
);
const SendEmailToStudent = React.lazy(() =>
  import("../studios/Students/Subs/SendEmail")
);
const CreateInvoice = React.lazy(() =>
  import("../studios/Students/Subs/CreateInvoice")
);
const StudentPipeline = React.lazy(() =>
  import("../studios/Students/StudentPipeline")
);
const QuickPay = React.lazy(() => import("../studios/Students/Subs/QuickPay"));
const RecordPayment = React.lazy(() =>
  import("../studios/Students/Subs/RecordPayment")
);
const SearchPayments = React.lazy(() =>
  import("../studios/Payments/SearchPayments")
);

const LatePaymentPipeline = React.lazy(() =>
  import("../studios/Payments/LatePaymentPipeline")
);
const AddLatePaymentPipeline = React.lazy(() => import("../studios/Payments/Subs/AddLatePaymentPipeline"));
const PaymentInfo = React.lazy(() =>
  import("../studios/Payments/Subs/PaymentInfo")
);
const ViewInvoices = React.lazy(() =>
  import("../studios/Payments/ViewInvoices")
);
const UpdateBillingInfo = React.lazy(() =>
  import("../studios/Students/Subs/UpdateBillingInfo")
);
const ViewStaff = React.lazy(() => import("../studios/Staff/ViewStaff"));
const ViewAStaffMember = React.lazy(() =>
  import("../studios/Staff/Subs/ViewAStaffMember")
);
const AddStaff = React.lazy(() => import("../studios/Staff/AddStaff"));
const SearchStaff = React.lazy(() => import("../studios/Staff/SearchStaff"));
const ViewClasses = React.lazy(() => import("../studios/Classes/ViewClasses"));
const AddAClass = React.lazy(() => import("../studios/Classes/Subs/AddAClass"));
const AddRoom = React.lazy(() => import("../studios/Classes/Subs/AddRoom"));
const UpdateRoom = React.lazy(() => import("../studios/Classes/Subs/UpdateRoom"));
const EditClass = React.lazy(() => import("../studios/Classes/Subs/EditClass"));
const AddARank = React.lazy(() => import("../studios/Classes/Subs/AddARank"));
const StudentRosterForClasses = React.lazy(() =>
  import("../studios/Classes/Subs/StudentsRosterForClasses")
);
const Attendance = React.lazy(() => import("../studios/Classes/Attendance"));
const Rooms = React.lazy(() => import("../studios/Classes/Rooms"));
const WaitingLists = React.lazy(() =>
  import("../studios/Classes/WaitingLists")
);
const CreateWaitingList = React.lazy(() =>
  import("../studios/Classes/Subs/CreateWaitingList")
);
const Programs = React.lazy(() => import("../studios/Classes/Programs"));
const AddAProgram = React.lazy(() =>
  import("../studios/Classes/Subs/AddAProgram")
);
const StudentRosterForPrograms = React.lazy(() =>
  import("../studios/Classes/Subs/StudentRosterForPrograms")
);
const Ranks = React.lazy(() => import("../studios/Classes/Ranks"));
const ViewStudentsInRank = React.lazy(() =>
  import("../studios/Classes/Subs/ViewStudentsInRank")
);
const Methods = React.lazy(() => import("../studios/Marketing/Methods"));
const AddMethod = React.lazy(() => import("../studios/Marketing/Subs/AddMethod"));
const EditMethod = React.lazy(() => import("../studios/Marketing/Subs/EditMethod"));
const AddProspect = React.lazy(() =>
  import("../studios/Marketing/AddProspect")
);
const SearchProspects = React.lazy(() =>
  import("../studios/Marketing/SearchProspects")
);
const ProspectPipeline = React.lazy(() =>
  import("../studios/Marketing/ProspectPipeline")
);
const DailySchedule = React.lazy(() =>
  import("../studios/Marketing/DailySchedule")
);
const StudentReports = React.lazy(() =>
  import("../studios/Reports/StudentReports")
);
const PaymentReports = React.lazy(() =>
  import("../studios/Reports/PaymentReports")
);
const MarketingReports = React.lazy(() =>
  import("../studios/Reports/MarketingReports")
);
const ViewEmails = React.lazy(() => import("../studios/Advanced/ViewEmails"));
const ViewNewsLetters = React.lazy(() =>
  import("../studios/Advanced/ViewNewsLetters")
);
const EmailingLists = React.lazy(() =>
  import("../studios/Advanced/EmailingLists")
);
const TextMessages = React.lazy(() =>
  import("../studios/Advanced/TextMessages")
);
const CaptureForms = React.lazy(() =>
  import("../studios/Advanced/CaptureForms")
);
const CreateCaptureForm = React.lazy(() => 
import("../studios/Advanced/Subs/CreateCaptureForm")
)
const PerformancePlanner = React.lazy(() =>
  import("../studios/Advanced/PerformancePlanner")
);
const Waivers = React.lazy(() => import("../studios/Advanced/Waivers"));
const StudentPortal = React.lazy(() =>
  import("../studios/Advanced/StudentPortal")
);

const routeConfig = [
  {
    path: "/",
    component: <Overview />,
  },
  {
    path: "/search",
    component: <SearchDisplay />,
  },
  {
    path: "/support",
    component: <Support />,
  },
  {
    path: "/manage-account",
    component: <ManageAccountInfo />,
  },
  {
    path: "/software-training",
    component: <SoftwareTraining />,
  },
  {
    path: "/account-status",
    component: <AccountStatus />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
  {
    path: "/dashboard",
    component: <Overview />,
  },
  {
    path: "/students",
    component: <Students />,
  },
  {
    path: "/students/add-student",
    component: <AddStudent />,
  },
  {
    path: "/students/view-student",
    component: <ViewStudent />,
  },
  {
    path: "/students/edit-student",
    component: <EditStudent />,
  },
  {
    path: "/students/view-student/send-email",
    component: <SendEmailToStudent />,
  },
  {
    path: "/students/search-students",
    component: <SearchStudents />,
  },
  {
    path: "/students/student-pipeline",
    component: <StudentPipeline />,
  },
  {
    path: "/students/student-pipeline/view-students-in-pipeline/:id",
    component: <ViewStudentsInPipeline />,
  },
  {
    path: "/students/student-pipeline/add-pipeline-step",
    component: <AddStudentPipelineStep />,
  },
  {
    path: "/students/student-pipeline/edit-pipeline-step/:id",
    component: <EditPipelineStep />,
  },
  {
    path: "/students/view-student/quick-pay",
    component: <QuickPay />,
  },
  {
    path: "/students/view-student/email-history",
    component: <ViewStudentEmailHistory />,
  },
  {
    path: "/students/view-student/update-billing",
    component: <UpdateBillingInfo />,
  },
  {
    path: "/students/view-student/create-invoice",
    component: <CreateInvoice />,
  },
  {
    path: "/students/view-student/quick-pay/record-payment",
    component: <RecordPayment />,
  },
  {
    path: "/payments",
    component: <SearchPayments />,
  },
  {
    path: "/payments/payment-info/:id",
    component: <PaymentInfo />,
  },
  {
    path: "/payments/late-payment-pipeline",
    component: <LatePaymentPipeline />,
  },
  {
    path: "/payments/late-payment-pipeline/add-step",
    component: <AddLatePaymentPipeline />,
  },
  {
    path: "/payments/view-invoices",
    component: <ViewInvoices />,
  },
  {
    path: "/staff",
    component: <ViewStaff />,
  },
  {
    path: "/staff/add-staff",
    component: <AddStaff />,
  },
  {
    path: "/staff/search-staff",
    component: <SearchStaff />,
  },
  {
    path: "/staff/view-a-staff-member",
    component: <ViewAStaffMember />,
  },
  {
    path: "/classes",
    component: <ViewClasses />,
  },
  {
    path: "/classes/add-student",
    component: <AddStudentToClass />,
  },
  {
    path: "/classes/add-class",
    component: <AddAClass />,
  },
  {
    path: "/classes/edit-class",
    component: <EditClass />,
  },
  {
    path: "/classes/attendance",
    component: <Attendance />,
  },
  {
    path: "/classes/roster",
    component: <StudentRosterForClasses />,
  },
  {
    path: "/classes/rooms",
    component: <Rooms />,
  },
  {
    path: "/classes/rooms/add-room",
    component: <AddRoom />,
  },
  {
    path: "/classes/rooms/update-room",
    component: <UpdateRoom />,
  },
  {
    path: "/classes/waiting-lists",
    component: <WaitingLists />,
  },
  {
    path: "/classes/waiting-lists/create-waiting-list",
    component: <CreateWaitingList />,
  },
  {
    path: "/classes/programs",
    component: <Programs />,
  },
  {
    path: "/classes/programs/add-program",
    component: <AddAProgram />,
  },
  {
    path: "/classes/programs/view-roster",
    component: <StudentRosterForPrograms />,
  },
  {
    path: "/classes/ranks",
    component: <Ranks />,
  },
  {
    path: "/classes/ranks/create-rank",
    component: <AddARank />,
  },
  {
    path: "/classes/ranks/:id",
    component: <ViewStudentsInRank />,
  },
  {
    path: "/marketing/methods",
    component: <Methods />,
  },
  {
    path: "/marketing/methods/add-method",
    component: <AddMethod />,
  },
  {
    path: "/marketing/methods/edit-method",
    component: <EditMethod />,
  },
  {
    path: "/marketing/add-prospect",
    component: <AddProspect />,
  },
  {
    path: "/marketing/search-prospects",
    component: <SearchProspects />,
  },
  {
    path: "/marketing/prospect-pipeline",
    component: <ProspectPipeline />,
  },
  {
    path: "/marketing/prospect-pipeline/view-roster/:id",
    component: <ProspectPipelineRoster />,
  },
  {
    path: "/marketing/prospect-pipeline/add-pipeline-step",
    component: <AddProspectPipelineStep />,
  },
  {
    path: "/marketing/daily-schedule",
    component: <DailySchedule />,
  },
  {
    path: "/reports/student-reports",
    component: <StudentReports />,
  },
  {
    path: "/reports/payment-reports",
    component: <PaymentReports />,
  },
  {
    path: "/reports/marketing-reports",
    component: <MarketingReports />,
  },

  {
    path: "/advanced/emails",
    component: <ViewEmails />,
  },
  {
    path: "/advanced/newsletters",
    component: <ViewNewsLetters />,
  },
  {
    path: "/advanced/emailing-lists",
    component: <EmailingLists />,
  },
  {
    path: "/advanced/text-messages",
    component: <TextMessages />,
  },
  {
    path: "/advanced/capture-forms",
    component: <CaptureForms />,
  },
  {
    path: "/advanced/capture-forms/create-form",
    component: <CreateCaptureForm />,
  },
  {
    path: "/advanced/performance-planner",
    component: <PerformancePlanner />,
  },
  {
    path: "/advanced/waivers",
    component: <Waivers />,
  },
  {
    path: "/advanced/student-portal",
    component: <StudentPortal />,
  },
];

export default function StudioRoutes() {
  return (
    <Dashboard>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routeConfig.map((route, index) => (
            <Route key={index} path={route.path} element={route.component} />
          ))}
          <Route path="/*" element={<Overview />} />
        </Routes>
      </Suspense>
    </Dashboard>
  );
}
