import { PlusCircle } from "lucide-react";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import { convertPhone, fetchFromAPI } from "../../../functions/shared";
import Loader from "../../../components/Loader";
import {
  addStudentToClass,
  dropStudentFromClass,
  getStudentsByClassId,
} from "../../../functions/api";
import DeleteStudentWarning from "../components/DeleteStudentWarning";

export default function AddStudentToClass() {
  const { suid, setStudentToEdit, idValue, classes } = UserAuth();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [classRoster, setClassRoster] = useState([]);
  const [classToRoster, setClassToRoster] = useState(null);
  const itemsPerPage = 20;

  const navigate = useNavigate();

  const handleStudentToEdit = (id) => {
    setStudentToEdit(id);
    navigate("/students/view-student");
  };

  const getAllStudents = async (id) => {
    setLoading(true);
    try {
      const response = await fetchFromAPI(
        `studio-access/getStudentsByStudioId/${id}/1`,
        {
          method: "GET",
        }
      );
      setStudents(response.recordset);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleGetStudents = (id) => {
    if (idValue) {
      try {
        getStudentsByClassId(idValue).then((res) => {
          setClassRoster(res);
          setLoading(false);
        });
        const classToRoster = classes.find((c) => c.ClassId === idValue);
        setClassToRoster(classToRoster);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      navigate("/classes");
    }
  };

  useEffect(() => {
    getAllStudents(suid);
    handleGetStudents(idValue);
    setLoading(false);
  }, [idValue, suid]);

  // Calculate total number of pages
  const totalPages = Math.ceil(students?.length / itemsPerPage);

  // Slice students array based on current page and items per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    if (search) {
      const filteredStudents = students.filter((student) => {
        return student.Name.toLowerCase().includes(search.toLowerCase());
      });
      setDisplayedStudents(filteredStudents);
      setLoading(false);
    } else {
      setDisplayedStudents(students?.slice(startIndex, endIndex));
      setLoading(false);
    }
  }, [search, students, startIndex, endIndex]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleAddToClass = async (studentId) => {
    try {
      addStudentToClass(studentId, idValue).then((res) => {
        console.log(res);
        handleGetStudents(idValue);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropFromClass = async (studentId) => {
    try {
      dropStudentFromClass(studentId, idValue).then((res) => {
        console.log(res);
        handleGetStudents(idValue);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {students.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Select all Students in{" "}
                <span className="text-com font-extrabold">
                  {classToRoster?.Name}
                </span>
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                *Students with a highlighted background need to renew payments.
              </p>
        
            </div>

          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 "
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell"
                          >
                            Phone
                          </th>

                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {displayedStudents?.map((person) => (
                          <tr key={person.Student_ID}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.Name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                              {person.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                              {convertPhone(person.Phone)}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              {classRoster?.find(
                                (student) =>
                                  student.Student_ID === person.Student_ID
                              ) ? (
                                <button
                                  className="rounded-sm bg-alert px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-alerthover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com flex items-center justify-center w-full whitespace-nowrap"
                                  onClick={() =>
                                    handleDropFromClass(person.Student_ID)
                                  }
                                >
                                  Drop
                                </button>
                              ) : (
                                <button
                                  className="rounded-sm bg-com px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-comhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com flex items-center justify-center w-full whitespace-nowrap"
                                  onClick={() =>
                                    handleAddToClass(person.Student_ID)
                                  }
                                >
                                  Add
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <nav
                  className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">{startIndex + 1}</span> to{" "}
                      <span className="font-medium">
                        {endIndex > students?.length
                          ? students?.length
                          : endIndex}
                      </span>{" "}
                      of <span className="font-medium">{students?.length}</span>{" "}
                      results
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                      className="relative inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 bg-white"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                    <button
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 bg-white"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center h-96">
          <div>
            <h1 className="text-xl font-semibold text-gray-700">
              No students found
            </h1>
            <div className="mt-4">
              <Link
                to="/students/add-student"
                type="button"
                className="rounded-sm bg-com px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-comhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-com flex items-center justify-center"
              >
                <PlusCircle className="w-5 h-5 mr-1" />
                Add a Student
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
