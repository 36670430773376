import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { dropStudent } from "../../../functions/api";
import toast, { Toaster } from "react-hot-toast";

export default function DeleteStudentWarning({
  id,
  roomName,
  type,
  updated,
  setUpdated,
  person
}) {
  const [open, setOpen] = useState(false);

  const name = type === "studentPipeline" ? "Student Pipeline Step" : type;

  console.log(person)

  //   const handleDeleteRoom = () => {
  //     dropRoom(id).then((response) => {
  //       console.log(response);
  //       if (response.status === 200) {
  //         setOpen(false);
  //         setUpdated(true);
  //       } else {
  //         console.error("Error deleting room");
  //         setOpen(false);
  //       }
  //     });
  //   };

  //   const handleDeleteMethod = () => {
  //     dropMarketingMethod(id).then((response) => {
  //       console.log(response);
  //       if (response.status === 200) {
  //         setOpen(false);
  //         window.location.reload();
  //       } else {
  //         console.error("Error deleting method");
  //         setOpen(false);
  //       }
  //     });
  //   };

  //   const handleDeleteStudentPipelineStep = () => {
  //     deleteStudentPipelineStep(id).then((response) => {
  //       console.log(response);
  //       if (response.status === 200) {
  //         setOpen(false);
  //         window.location.reload();
  //       } else {
  //         console.error("Error deleting student pipeline step");
  //         setOpen(false);
  //       }
  //     });
  //   };

  const studentToDelete = {
    studentId: person?.Student_ID,
    activityLevel: 0
  }

  const handleSubmit = () => {
    console.log("delete");
    dropStudent(studentToDelete).then((response) => {
      console.log(response);
      if (response) {
        setOpen(false);
        setUpdated(!updated);
        toast.success(`Student has been deleted.`);
      } else {
        console.error("Error deleting student");
        setOpen(false);
      }
    });
  };



  return (
    <>
    <Toaster position="top-center" reverseOrder={false} />
      <button
        href="#"
        className="text-alert hover:text-alerthover"
        onClick={() => setOpen(true)}
      >
        Delete
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Warning
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete {person.First_Name}? All of
                          the data associated with {person.First_Name} will be
                          set to inactive and they will be removed from all classes, programs, and payment schedules.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleSubmit}
                    >
                      Delete {person.First_Name} {person.Last_Name}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
