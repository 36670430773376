import { Route, Routes } from "react-router-dom";
import { UserAuth } from "./context/AuthContext";
import Hero from "./landing/components/Hero";
import NavBar from "./landing/components/NavBar";
import Login from "./landing/pages/Login";
import CreateAccount from "./landing/pages/CreateAccount";
import Loading from "./components/Loading";
import { logout } from "./functions/auth";
import Dashboard from "./studios/Dashboard";
import StudioRoutes from "./router/StudioRoutes";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const { isLoggedIn, isLoading } = UserAuth();

  return (
    <>
      <ScrollToTop >
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {isLoggedIn ? (
              <div>
                <StudioRoutes />
              </div>
            ) : (
              <div>
                <NavBar />
                <Routes>
                  <Route path="/" element={<Hero />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/create-account" element={<CreateAccount />} />
                  <Route path="/*" element={<Hero />} />
                </Routes>
              </div>
            )}
          </div>
        )}
      </ScrollToTop>
    </>
  );
}

export default App;
