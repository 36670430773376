import { Loader2 } from 'lucide-react'
import React from 'react'

export default function Loader() {
  return (
    <div className="flex items-center justify-center">
          <div>
            <Loader2 className="w-16 h-16 text-com animate-spin rounded-full" />
          </div>
        </div>
  )
}
