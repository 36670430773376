import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  getStudioOptions,
  getTextMessageThread,
  sendAText,
} from "../../functions/api";
import { format, set } from "date-fns";

export default function Chat() {
  const { currentStudent, studentToEdit, studioInfo, suid } = UserAuth();
  const [textmessages, setTextMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [canText, setCanText] = useState(false);

  const navigate = useNavigate();

  const checkIfStudioCanText = () => {
    getStudioOptions(suid).then((res) => {
      if (res && res.recordset && res.recordset.length > 0) {
        if (
          res.recordset[0].TextFromNumber &&
          res.recordset[0].TextFromNumber !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  };

  useEffect(() => {
    if (!studentToEdit) {
      navigate("/students");
    } else {
      const canStudioText = checkIfStudioCanText();
      if (!canStudioText) {
        setCanText(false);
      } else {
        setCanText(true);
        const toNumber = currentStudent?.Phone;
        const fromNumber = currentStudent?.Phone;
        getTextMessageThread(suid, toNumber, fromNumber).then((res) => {
          console.log("res", res);
          if (res && res.recordset && res.recordset.length > 0) {
            const texts = res.recordset?.sort((a, b) => {
              return new Date(a.SendDate) - new Date(b.SendDate);
            });

            setTextMessages(texts);
          } else {
            // Handle case where recordset is undefined or empty
            // You can set default values or perform any other necessary actions
            console.error("Recordset is undefined or empty");
            setTextMessages([]);
          }
        });
      }
    }
  }, [studentToEdit]);

  const handleSendSMS = () => {
    console.log("Sending SMS");
    sendAText(currentStudent?.Phone, newMessage, suid).then((res) => {
      console.log("res", res);
    });
  };

  return (
    <>
    {canText ? (<div className="flex  overflow-hidden shadow">
      <div className="flex-1 ">
        <header className="bg-white p-4 text-gray-700 rounded-t-lg">
          <h1 className="text-2xl font-semibold">
            {currentStudent?.First_Name} {currentStudent?.Last_Name} -{" "}
            {currentStudent?.Phone}
          </h1>
        </header>

        <div className="h-screen overflow-y-auto p-4 pb-36 bg-zinc-50">
          {textmessages?.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.FromNumber === currentStudent?.Phone
                  ? "justify-start"
                  : "justify-end"
              } mb-4 cursor-pointer`}
            >
              <div
                className={`w-9 h-9 rounded-full flex items-center justify-center ${
                  message.FromNumber === currentStudent?.Phone ? "ml-2" : "mr-2"
                }`}
              >
                {message.FromNumber === currentStudent?.Phone && (
                  <img
                    src="https://placehold.co/200x/b7a8ff/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato"
                    alt="User Avatar"
                    className="w-8 h-8 rounded-full"
                  />
                )}
              </div>
              <div
                className={`flex text-clip overflow-hidden max-w-96 ${
                  message.FromNumber === currentStudent?.Phone
                    ? "bg-indigo-500 text-white rounded-lg p-3 gap-3 "
                    : "bg-white rounded-lg p-3 gap-3 "
                }`}
              >
                <div className="p-1">
                  <p className="break-words">{message.Body}</p>
                  <p className="text-xs text-gray-500 text-right mt-2">
                    {format(message.SendDate, "MM/dd/yyyy hh:mm a")}
                  </p>
                </div>
              </div>

              {message.FromNumber !== currentStudent?.Phone && (
                <img
                  src="https://placehold.co/200x/b7a8ff/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato"
                  alt="User Avatar"
                  className="w-8 h-8 rounded-full ml-2"
                />
              )}
            </div>
          ))}
        </div>

        <footer className="bg-white border-t border-gray-300 p-4 fixed bottom-0 right-0 left-0 pl-80">
          <div className="flex items-center">
            <textarea
              rows={1}
              placeholder="Type a message..."
              className="w-full p-2 rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              className="bg-indigo-500 text-white px-4 py-2 rounded-md ml-2"
              onClick={handleSendSMS}
            >
              Send
            </button>
          </div>
        </footer>
      </div>
    </div>) : (
        <div className="flex min-h-full flex-col pb-12 pt-16">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
       
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-com">FEATURE NOT ENABLED</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                This account is not enabled for texting.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Please contact Compete Studio if you feel this is an error.
              </p>
              {/* <div className="mt-6">
                <Link to="/" className="text-base font-medium text-com hover:text-comhover">
                  Enable Texting
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div> */}
            </div>
          </div>
        </main>
      </div>
    )}
    
    </>
  );
}
