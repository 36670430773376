import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase/firebase";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { fetchFromAPI } from "../functions/shared";

const alertsInit = {
  addedStudents: false,
  addedClasses: false,
  addedMarketing: false,
  addedPipeline: false,
  addedSchedule: false,
};

const UserContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [alerts, setAlerts] = useState(alertsInit);
  const [studioInfo, setStudioInfo] = useState(null);
  const [idValue, setIdValue] = useState(null);
  const [user, setUser] = useState(null);
  const [suid, setSuid] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [waitingLists, setWaitingLists] = useState([]);
  const [marketingSource, setMarketingSource] = useState([]);
  const [pipelineSteps, setPipelineSteps] = useState([]);
  const [studentToEdit, setStudentToEdit] = useState(null);
  const [prospectPipelineSteps, setProspectPipelineSteps] = useState([]);
  const [scheduleId, setScheduleId] = useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [events, setEvents] = useState([]);
  const [staffMemberToView, setStaffMemberToView] = useState(null);
  const [searchedStudentsAndProspects, setSearchedStudentsAndProspects] =
    useState([]);

  const getStudentInfo = (studentId) => {
    try {
      const response = fetchFromAPI(
        `student-access/getStudentInfo/${studentId}`,
        {
          method: "GET",
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentInfoForGLobal = async (studentId) => {
    getStudentInfo(studentId).then((response) => {
      setCurrentStudent(response.recordset[0]);
      if (!response.recordset[0].length > 0) {
        setAlerts({
          ...alerts,
          addedStudents: true,
        });
      } else {
        setAlerts({
          ...alerts,
          addedStudents: false,
        });
      }
    });
  };

  useEffect(() => {
    if (studentToEdit !== null) {
      getStudentInfoForGLobal(studentToEdit);
    }
  }, [studentToEdit]);

  const getClassesByStudioID = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `studio-access/getClassesByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setClasses(response.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getProgramsByStudioID = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `class-access/getProgramsByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setPrograms(response.result.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getWaitingListsByStudioID = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `marketing-access/getWaitingListsByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setWaitingLists(response.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getMarketingSourceByStudioID = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `marketing-access/getMarketingMethodsByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setMarketingSource(response.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentPipelineStepsByStudioId = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `marketing-access/getStudentPipelineStepsByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setPipelineSteps(response.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getProspectPipelineStepsByStudioId = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `marketing-access/getPipelineStepsByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      setProspectPipelineSteps(response.recordset);
    } catch (error) {
      console.error(error);
    }
  };

  const getDailyScheduleByStudioId = async (studioId) => {
    try {
      const response = await fetchFromAPI(
        `daily-schedule-tools/getDailyScheduleByStudioId/${studioId}`,
        {
          method: "GET",
        }
      );
      const pipelineStepsResponse = await fetchFromAPI(
        `daily-schedule-tools/getSPStepsForSchedule/${response.recordset[0].ScheduleId}/${studioId}`,
        {
          method: "GET",
        }
      );
      setScheduleId(pipelineStepsResponse.recordset);
    } catch (error) {
      console.error(error);
    }
  };

    const getEventsByStudioId = async (studioId) => {
    try {
      const eventsRef = collection(db, "events");
      const stuToString = studioId.toString();
      const q = query(eventsRef, where("studioID", "==", stuToString));
      const querySnapshot = await getDocs(q);
      const events = [];
      querySnapshot.forEach((doc) => {
        events.push(doc.data());
      });
      setEvents(events);
    } catch (error) {
      console.error(error);
    }
  };

  const getStudioInfo = async (id) => {
    try {
      const docRef = doc(db, "studios", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStudioInfo(docSnap.data());
        getClassesByStudioID(docSnap.data().Studio_Id);
        getProgramsByStudioID(docSnap.data().Studio_Id);
        getWaitingListsByStudioID(docSnap.data().Studio_Id);
        getMarketingSourceByStudioID(docSnap.data().Studio_Id);
        getStudentPipelineStepsByStudioId(docSnap.data().Studio_Id);
        getProspectPipelineStepsByStudioId(docSnap.data().Studio_Id);
        getDailyScheduleByStudioId(docSnap.data().Studio_Id);
        getEventsByStudioId(docSnap.data().Studio_Id);
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };



  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setIsLoggedIn(true);
        console.log("It ran again", currentUser.photoURL);
        setSuid(currentUser.photoURL);
        getStudioInfo(currentUser.photoURL);
        setIsLoading(false);
      } else {
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe(); // Clean up the onAuthStateChanged listener
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        suid,
        isLoading,
        setIsLoading,
        studioInfo,
        classes,
        programs,
        waitingLists,
        marketingSource,
        pipelineSteps,
        studentToEdit,
        setStudentToEdit,
        prospectPipelineSteps,
        scheduleId,
        currentStudent,
        idValue,
        setIdValue,
        events,
        setEvents,
        alerts,
        setStaffMemberToView,
        staffMemberToView,
        searchedStudentsAndProspects,
        setSearchedStudentsAndProspects,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
};
